
import menuMixin from "@/mixins/menu"

export default menuMixin.extend({
  data: () => ({
    menuOpen: false,
  }),

  mounted () {
    this.$nuxt.$on("open-login", this.openLogin)
  },
  beforeDestroy () {
    this.$nuxt.$off("open-login", this.openLogin)
  },

  methods: {
    openLogin () {
      this.$router.push("/auth/login")
    },

    onLoginClick () {
      this.$router.push("/auth/login")
      this.$ym?.("reachGoal", "click_button_yapoidu")
    },
  },
})
