export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: (context) => import('@/plugins/vue-i18n').then(m => m.default(context)),
  vueI18nLoader: false,
  locales: [{"code":"ru","file":"ru"},{"code":"en","file":"en"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "@/locale",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: false,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ru","file":"ru"},{"code":"en","file":"en"}],
  localeCodes: ["ru","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "r",
  3: "u",
  4: "\"",
  5: ":",
  6: "\"",
  7: ".",
  8: ".",
  9: "/",
  10: "l",
  11: "o",
  12: "c",
  13: "a",
  14: "l",
  15: "e",
  16: "/",
  17: "r",
  18: "u",
  19: "\"",
  20: ",",
  21: "\"",
  22: "e",
  23: "n",
  24: "\"",
  25: ":",
  26: "\"",
  27: ".",
  28: ".",
  29: "/",
  30: "l",
  31: "o",
  32: "c",
  33: "a",
  34: "l",
  35: "e",
  36: "/",
  37: "e",
  38: "n",
  39: "\"",
  40: "}",
}

export const localeMessages = {
  'ru': () => import('../../locale/ru' /* webpackChunkName: "lang-ru" */),
  'en': () => import('../../locale/en' /* webpackChunkName: "lang-en" */),
}
