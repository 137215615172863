import { onLoad } from "@/utils/_"

// https://yandex.ru/support/metrica/general/check-goal.html
const getYm = (id: number) =>
  (...args: any[]) => window?.ym?.(id, ...args)

const plugin: NuxtPlugin = ({ isDev, store }, inject) => {
  if (isDev) return
  const id = store.state.app.event?.metadata?.metrics?.yandexMetrika || ""
  if (!id) return

  onLoad(() => {
    const script = document.createElement("script")
    script.text = `
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      ym("${id}", "init", {
        clickmap:true,
        trackHash:true,
        trackLinks:true,
        webvisor:true,
        accurateTrackBounce:true
      });
    `
    document.body.appendChild(script)
  })

  inject("ym", getYm(id))
}
export default plugin

type Ym = ReturnType<typeof getYm>
declare module "vue/types/vue" {
  interface Vue { $ym?: Ym }
}
declare module "@nuxt/types" {
  interface NuxtAppOptions { $ym?: Ym }
  interface Context { $ym?: Ym }
}
declare module "vuex/types/index" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> { $ym?: Ym }
}
