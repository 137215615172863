
import Vue from "vue"
import { mapState } from "vuex"
import pickBy from "lodash/pickBy"

interface Contact {
  title: string
  items: {
    label?: string
    value: string
    type: "url" | "email" | "tel"
  }[]
}

const defaultContacts = [
  {
    title: "ВОИРФЕСТ",
    items: [
      { value: "https://voirfest.ru", type: "url" },
      { label: "Общие вопросы", value: "info@voirfest.ru", type: "email" },
      { label: "Пресс-служба", value: "pr@voirfest.ru", type: "email" },
    ],
  },
  {
    title: "ВОИР",
    items: [
      { value: "https://voir-russia.ru", type: "url" },
      { value: "+7 (958) 146-95-57", type: "tel" },
    ],
  },
]

export default Vue.extend({
  props: {
    contacts: {
      type: Array as VuePropType<Contact[]>,
      default: () => defaultContacts,
    },

    withMap: {
      type: Boolean, default: false,
    },
  },

  computed: {
    ...mapState("app", ["event"]),

    socials (): Record<string, string> {
      const socials = pickBy(this.event?.links || {}, Boolean)

      socials.dzen = "https://dzen.ru/voirfest"
      socials.youtube = "https://www.youtube.com/@voirfest"
      socials.vkvideo = "https://vk.com/video/@voir_fest"

      return socials
    },
  },

  methods: {
    getHref (value: string, type: Contact["items"][number]["type"]): string {
      let pfx = ""
      if (type === "email") pfx = "mailto:"
      else if (type === "tel") pfx = "tel:"
      return pfx + value
    },

    cleanUrl (url: string): string {
      return url.replace(/^https?:\/\//, "")
    },
  },
})
