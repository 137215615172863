// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Gh10rlOp{align-items:center;display:flex;flex-direction:column;flex-grow:1;justify-content:center;padding:var(--spacing-xxl) var(--spacing-sm)}.TAxZlmEf{max-width:100%;width:20rem}.rQqp9795{font-size:1.44rem;font-weight:400;line-height:1.4;margin-bottom:calc(var(--spacing-default)*4.5);text-align:center;text-transform:uppercase}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "Gh10rlOp",
	"content": "TAxZlmEf",
	"heading": "rQqp9795"
};
module.exports = ___CSS_LOADER_EXPORT___;
