import { customAlphabet as nonSecureCustomAlphabet } from "nanoid/non-secure"
import type { User } from "@/types/api/person"

export function changeUrl (url: string) {
  window.history.replaceState({}, "", url)
}

export const isUserAdmin = (user: User) => Boolean(user) && [
  "admin",
  "superadmin",
].some(r => user.roles.includes(r))

export function getFailToken (
  error: NuxtContext["error"], i18n: NuxtContext["i18n"],
) {
  return () => error({
    statusCode: 404, message: i18n.t("error.error") as string,
  })
}

export function onLoad (cb: Function, delay = 1) {
  const delayedCb = () => setTimeout(() => cb(), delay)
  if (document.readyState === "complete") delayedCb()
  else window.addEventListener("load", delayedCb, { once: true })
}

export function detectIos (): boolean {
  return [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform)
}

// "d" не используется чтобы избежать возможной "ad":
// https://github.com/styletron/styletron/issues/139
// id и class не могут начинаться с цифры
const alphaWithoutD =
  "abcefghijklmnopqrstuvwxyzABCEFGHIJKLMNOPQRSTUVWXYZ"

// Генератор id для html тегов
export const getHtmlId = nonSecureCustomAlphabet(alphaWithoutD)

export const tagsPallete: Record<string, string> = {
  "f12a97d9-690e-4868-a06d-e751b7fa757b": "#BF2333",
  "6179fd66-dd6e-4987-a1ec-2b713d8a18ca": "#47DD5F",
  "4959a73c-abb7-4b6a-9082-03b1bf1e9361": "#2DAECB",
  "9e24ba1e-58be-4663-8979-cde1da602855": "#C82085",
  "1301dfdc-9d48-4bdb-b23a-0f9643dbae56": "#D76700",
  "762f690f-c475-4ed3-b209-7a73a138396a": "#574240",
}
