
import Vue from "vue"
import throttle from "lodash/throttle"

export default Vue.extend({
  props: {
    settings: { type: Object, default: () => ({}) },
  },

  data: () => ({
    isToTopBtnVisible: false,
  }),

  mounted () {
    this.onScroll()
    window.addEventListener("scroll", this.onScroll, { passive: true })
  },

  beforeDestroy () {
    window.removeEventListener("scroll", this.onScroll)
  },

  methods: {
    scrollToTop () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },

    onScroll: throttle(function (this: any) {
      this.isToTopBtnVisible = window.scrollY > 400
    }, 300),
  },
})
